<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
        <el-row :gutter="15">
          <el-col :md="6" :sm="12">
            <el-form-item label="用户账号:">
              <el-input
                      v-model="where.username"
                      placeholder="请输入"
                      clearable/>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <el-form-item label="手机号:">
              <el-input
                      v-model="where.phone"
                      placeholder="请输入"
                      clearable/>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <div class="ele-form-actions">
              <el-button
                      type="primary"
                      @click="reload"
                      icon="el-icon-search"
                      class="ele-btn-icon">查询
              </el-button>
              <el-button @click="reset">重置</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- 数据表格 -->
      <ele-pro-table
              ref="table"
              :response="response"
              :datasource="url"
              :columns="columns"
              :where="where"
              :selection.sync="selection">
        <!-- 表头工具栏 -->
        <template slot="toolbar">
          <el-button
                  @click="openEdit(null)"
                  type="primary"
                  icon="el-icon-plus"
                  class="ele-btn-icon"
                  size="small">添加
          </el-button>
        </template>
        <!-- 角色列 -->
        <template slot="roles" slot-scope="{row}">
          <el-tag
                  v-for="item in row.roles"
                  :key="item.role_id"
                  type="primary"
                  size="mini">
            {{ item.role_name }}
          </el-tag>
        </template>
        <!-- 状态列 -->
        <template slot="state" slot-scope="{row}">
          <el-switch
                  v-model="row.state"
                  @change="editState(row)"
                  :active-value="0"
                  :inactive-value="1"/>
        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="{row}">
          <el-link
                  @click="openEdit(row)"
                  icon="el-icon-edit"
                  type="primary"
                  :underline="false">修改
          </el-link>
          <el-popconfirm v-if="row.id !== 1"
                         title="确定要删除此用户吗？"
                         @confirm="remove(row)"
                         class="ele-action">
            <el-link
                    slot="reference"
                    icon="el-icon-delete"
                    type="danger"
                    :underline="false">删除
            </el-link>
          </el-popconfirm>
        </template>
      </ele-pro-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <user-edit
            :visible.sync="showEdit"
            :data="current"
            @done="reload"/>
  </div>
</template>

<script>
  import UserEdit from './stem-edit';

  export default {
    name: 'SystemUser',
    components: {UserEdit},
    data() {
      var module_id = this.$route.params.id;
      //console.log(module_id)
      return {
        // 表格数据接口
        url: '/Admin/questionStem?module_id='+module_id,
        response: {
          statusCode: 200,
          dataName: 'data.data'
        },
        // 表格列配置
        columns: [
          {
            columnKey: 'index',
            type: 'index',
            width: 45,
            align: 'center',
            fixed: 'left',
            showOverflowTooltip: true
          },
          {
            prop: 'module_name',
            label: '模块',
            //sortable: 'custom',
            showOverflowTooltip: true,
            minWidth: 110
          },
          {
            prop: 'level_name',
            label: '等级',
            //sortable: 'custom',
            showOverflowTooltip: true,
            minWidth: 110
          },
          {
            prop: 'created_at',
            label: '创建时间',
            sortable: 'custom',
            showOverflowTooltip: true,
            minWidth: 110
          },
          {
            columnKey: 'action',
            label: '操作',
            width: 130,
            align: 'center',
            resizable: false,
            slot: 'action'
          }
        ],
        // 表格搜索条件
        where: {},
        // 表格选中数据
        selection: [],
        // 当前编辑数据
        current: null,
        // 是否显示编辑弹窗
        showEdit: false,
        // 是否显示导入弹窗
        showImport: false
      }
    },
    methods: {
      /* 刷新表格 */
      reload() {
        this.$refs.table.reload({page: 1});
      },
      /* 重置搜索 */
      reset() {
        this.where = {};
        this.$nextTick(() => {
          this.reload();
        });
      },
      /* 显示编辑 */
      openEdit(row) {
        this.current = row;
        this.showEdit = true;
      },
      /* 删除 */
      remove(row) {
        const loading = this.$loading({lock: true});
        this.$http.delete('/Admin/users/' + row.id).then(res => {
          loading.close();
          if (res.data.code === 200) {
            this.$message({type: 'success', message: res.data.msg});
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      },
      /* 更改状态 */
      editState(row) {
        const loading = this.$loading({lock: true});
        let params = {state: row.state};
        this.$http.put('/Admin/users/' + row.id, params).then(res => {
          loading.close();
          if (res.data.code === 200) {
            this.$message({type: 'success', message: res.data.msg});
          } else {
            row.state = !row.state ? 1 : 0;
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    }
  }
</script>

<style scoped>
</style>
